import React from "react";
import "../styles/ResearchSection.css";
import ResearchItem from "./ResearchItem";

const ResearchSection = () => {
  return (
    <div className="research-section">
      <h2>Research</h2>
      <div className="research-list">
        <ResearchItem
          title="🧹CleanDIFT: Diffusion Features without Noise"
          image="CleanDIFT.jpg"
          authors={[
            "Nick Stracke",
            "Stefan A. Baumann",
            "Kolja Bauer",
            "Frank Fundel",
            "Björn Ommer",
          ]}
          journal="arXiv"
          highlightedAuthor={3}
          description="A novel method to extract noise-free, timestep-independent features by enabling diffusion models to work directly with clean input images."
          arxivUrl="https://arxiv.org/pdf/2412.03439"
          codeUrl="https://github.com/CompVis/cleandift"
          projectUrl="https://compvis.github.io/cleandift/"
        />
        <ResearchItem
          title="Distillation of Diffusion Features for Semantic Correspondence"
          image="DistillDIFT.png"
          authors={[
            "Frank Fundel",
            "Johannes Schusterbauer",
            "Vincent Tao Hu",
            "Björn Ommer",
          ]}
          highlightedAuthor={0}
          journal="WACV 2025"
          description="We demonstrate how to distill two large vision foundation models into a smaller, high-accuracy model with lower computational cost."
          arxivUrl="https://arxiv.org/abs/2412.03512"
          codeUrl="https://github.com/compvis/distilldift"
          projectUrl="https://compvis.github.io/distilldift/"
          highlighted
        />
        <ResearchItem
          title="Automatic bat call classification using transformer networks"
          image="BAT.png"
          authors={["Frank Fundel", "Daniel A. Braun", "Sebastian Gottwald"]}
          highlightedAuthor={0}
          journal="Ecological Informatics"
          description="ConvNet-Transformer hybrid model enables sequence-based bat call classification, surpassing previous single call approaches.."
          arxivUrl="https://arxiv.org/abs/2309.11218"
          codeUrl="https://github.com/FrankFundel/BAT"
        />
      </div>
    </div>
  );
};

export default ResearchSection;
